import {
  createRouter,
  createWebHistory
} from 'vue-router'

function isAuthenticated() {
  return !!localStorage.getItem('authToken');
}

function isAuthenticatedTime() {
  const currentTime = Math.floor(Date.now() / 1000);
  const authTokenTime = localStorage.getItem('tokenTime');
  if (authTokenTime) {
    const timeDifference = currentTime - parseInt(authTokenTime, 10);
    const sevenDaysInSeconds = 7 * 24 * 60 * 60;
    return timeDifference <= sevenDaysInSeconds;
  }
  return false;
}

function isAdmin() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  return userInfo.is_superuser || userInfo.is_staff;
}

const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '登录到 校记活动'
    }
  },
  {
    path: '/wework',
    name: 'wework',
    component: () => import('../views/Login/Wework.vue'),
    meta: {
      title: 'SJCID Wework'
    }
  },
  {
    path: '/idCode',
    name: 'sjccode',
    component: () => import('../views/idCode/idCode.vue'),
    meta: {
      title: '校记身份码 SJCID',
      requiresAuth: true
    }
  },
  {
    path: '/idCode/check',
    name: 'sjccodecheck',
    component: () => import('../views/idCode/Check.vue'),
    meta: {
      title: '校记身份核验 SJCID',
      requiresAuth: true
    }
  },
  {
    path: '/idCode/info',
    name: 'sjcInfo',
    component: () => import('../views/idCode/Info.vue'),
    meta: {
      title: '校记工作台 SJCWS',
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'mindex',
    redirect: '/index',
    component: () => import('../views/HomeView'),
    meta: {
      title: '校记活动 SJCAC',
      requiresAuth: false
    },
    children: [

      {
        path: '/user',
        name: 'mUser',
        component: () => import('../views/idCode/Info.vue'),
        meta: {
          title: '个人信息维护',
          requiresAuth: true
        }
      },
      {
        path: '/index',
        name: 'mhome',
        component: () => import('../views/Home'),
        meta: {
          title: '校学生记者中心 活动广场',
          requiresAuth: false
        }
      },
      {
        path: '/item/:id',
        name: 'HomeItem',
        component: () => import('../views/Home/Item.vue'),
        meta: {
          title: '活动详情',
          requiresAuth: true,
        }
      },
      {
        path: '/ticket/:id',
        name: 'TicketItem',
        component: () => import('../views/Activity/Ticket.vue'),
        meta: {
          title: '我的电子票',
          requiresAuth: true,
        }
      },
      {
        path: '/codeSign/:uuid',
        name: 'codeSign',
        component: () => import('../views/Activity/codeSign.vue'),
        meta: {
          title: '签到码签到',
          requiresAuth: true,
        }
      },
      {
        path: '/record/:id',
        name: 'Record',
        component: () => import('../views/Activity/Record.vue'),
        meta: {
          title: '签到记录',
          requiresAuth: true,
        }
      },
    ],
  },
  {
    path: '/admin',
    name: 'adminIndex',
    redirect: '/admin/home',
    component: () => import('../views/Admin/Index.vue'),
    meta: {
      title: '校记活动',
      requiresAuth: true,
      requiresAdmin: true // 新增 meta 属性
    },
    children: [{
        path: 'home',
        name: 'adminHome',
        component: () => import('../views/Admin/Home.vue'),
        meta: {
          title: '校记活动',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'add',
        name: 'adminAdd',
        component: () => import('../views/Admin/Add.vue'),
        meta: {
          title: '新增活动',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'item/:id',
        name: 'adminItem',
        component: () => import('../views/Admin/Item.vue'),
        meta: {
          title: '活动详情',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'sign/:id',
        name: 'adminSign',
        component: () => import('../views/Admin/Sign.vue'),
        meta: {
          title: '活动签到',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'edit/:id',
        name: 'adminItemEdit',
        component: () => import('../views/Admin/Edit.vue'),
        meta: {
          title: '编辑活动',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'data/:id',
        name: 'Data',
        component: () => import('../views/Admin/Data.vue'),
        meta: {
          title: '数据统计',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
      {
        path: 'prize/:id',
        name: 'Prize',
        component: () => import('../views/Admin/submitPrize.vue'),
        meta: {
          title: '在线抽奖',
          requiresAuth: true,
          requiresAdmin: true // 新增 meta 属性
        }
      },
    ]
  },
  {
    path: '/prizePage',
    name: 'prizePage',
    component: () => import('../views/Admin/prizePage.vue'),
    meta: {
      title: '活动抽奖',
      requiresAuth: true,
      requiresAdmin: true // 新增 meta 属性
    }
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 全局导航守卫
router.beforeEach((to, from, next) => {


  const tokenTime = parseInt(localStorage.getItem('tokenTime')) || 0;
  const currentTime = Math.floor(new Date().getTime() / 1000);

  // 检查 tokenTime 是否超过七天（7 * 24 * 60 * 60 = 604800秒）
  if (tokenTime && (currentTime - tokenTime) >= 604800) {
    // 如果超过七天，不携带Token
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenTime');
    localStorage.removeItem('userInfo');
  }

  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 检查需要登录的页面
  if (to.meta.requiresAuth && (!isAuthenticatedTime() || !isAuthenticated())) {
    const currentPath = encodeURIComponent(to.fullPath); // 对当前路径进行编码
    const pathstate = currentPath
    next({
      name: 'login',
      query: {
        state: pathstate
      }
    }); // 跳转到登录页面
  } else if (to.meta.requiresAdmin && !isAdmin()) {
    // 检查需要管理员权限的页面
    next({
      name: 'noright'
    }); // 如果不是管理员，跳转到登录页面或其他处理
  } else {
    next(); // 继续导航
  }
})

// 全局后置守卫
router.afterEach(() => {
  // 滚动到页面顶部
  window.scrollTo(0, 0);
});

export default router