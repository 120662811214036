import {
    createApp
} from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/reset.css';
import router from './router'

import 'vant/lib/index.css';
import vant from 'vant'

import TDesignMobile from 'tdesign-mobile-vue'
import 'tdesign-mobile-vue/es/style/index.css'

import naive from 'naive-ui'

// 通用字体
import 'vfonts/Lato.css'
// 等宽字体
import 'vfonts/FiraCode.css'


const app = createApp(App);

app.use(router);


app.use(vant).use(TDesignMobile);

app.use(Antd);
app.use(naive).mount('#app');
